import moment from "moment";
import {
  AssociatedMeters,
  DownloadFileModel,
  MeasuresModel,
  MissingHoursStatus,
} from "../models/frontiers";
import Papa from "papaparse";
import { HOURS, REPORTS_LIST_ORDER, REPORT_RESULT_LIST_ORDERS } from "../const";
import {
  BorderResult,
  ReportAttemps,
  ReportAttempsStates,
  XMReportsModel,
} from "../models/reports";

export const utcToLocal = (date: string) => {
  const utcHour = moment.utc(date);
  const localHour = utcHour.local();

  return localHour.format("YYYY/MM/DD");
};

export const formatDateRange = (start: string, end: string) => {
  return moment(start).format("DD MMM");
};

export const generateId = () => {
  const id = Math.random().toString(36).substring(2);

  return id;
};

export const searchMissingHours = (measures: MeasuresModel[], date: string) => {
  const missingHours: Record<string, MissingHoursStatus> = {};
  const currentDate = moment().startOf("hour");

  HOURS.map((hour) => {
    const exists = measures.some(
      (measure) => measure.start.includes(hour) && measure.active_energy_exported !== null
    );
    const dateParam = moment(`${date}T${hour}`).startOf("hour");

    const isEstimated = measures.some(
      (measure) =>
        measure.start.includes(hour) &&
        measure.active_energy_exported !== null &&
        false
    );

    const isFutureHour = dateParam.isSameOrAfter(currentDate);

    if (!exists && !isFutureHour) {
      missingHours[hour] = MissingHoursStatus.MISSING;
    } else if (exists && !isFutureHour && !isEstimated) {
      missingHours[hour] = MissingHoursStatus.EXISTS;
    } else if (exists && !isFutureHour && isEstimated) {
      missingHours[hour] = MissingHoursStatus.ESTIMATED;
    } else {
      missingHours[hour] = MissingHoursStatus.OUT_OF_TIME;
    }
  });

  return missingHours;
};

export const formatRelationshipTree = (
  deviceId: string,
  meters: AssociatedMeters[],
  currentSerial: string
) => {
  const radius = 300;
  const centralNodeWidth = 150;
  const spaceBetweenNodes = 80;
  const numberOfNodes = 16;

  const deviceNode = {
    id: deviceId,
    type: "smartBit",
    data: { deviceId },
    position: { x: 300 - centralNodeWidth / 2, y: 300 - centralNodeWidth / 2 },
  };

  const meterNodes = meters.map((item, index: number) => {
    const angle = (2 * Math.PI * index) / numberOfNodes;
    return {
      id: item.meter_id,
      type: "meter",
      data: { serial: item.meter_serial, currentSerial },
      position: {
        x: 300 + (radius + spaceBetweenNodes) * Math.cos(angle) - centralNodeWidth / 2,
        y: 300 + (radius + spaceBetweenNodes) * Math.sin(angle) - centralNodeWidth / 2,
      },
    };
  });

  const tNodes: any = meterNodes;
  tNodes.push(deviceNode);

  const tEdges: any[] = [];

  tNodes.map((item: any, index: number) => {
    if (index !== tNodes.length - 1) {
      tEdges.push({
        id: `e${index + 1}`,
        source: deviceNode.id,
        target: item.id,
        animated: true,
      });
    }
  });

  return { nodes: tNodes, edges: tEdges };
};

export const groupMissingHours = (hours: MeasuresModel[]) => {
  const groupedData: Record<string, MeasuresModel[]> = {};

  hours.forEach((obj) => {
    const startDate = obj.start.substring(0, 10);

    if (groupedData[startDate]) {
      groupedData[startDate].push(obj);
    } else {
      groupedData[startDate] = [obj];
    }
  });

  return groupedData;
};

export const generateUsagesCsv = (usages: Record<string, MeasuresModel[]>, meterSerial: string) => {
  const fileRows = Object.keys(usages).map((key) => {
    const row: Record<string, any> = {
      serial: meterSerial,
      FECHA: moment(key).format("DD/MM/YYYY"),
    };

    HOURS.forEach((hour) => {
      const exists = usages[key].some(
        (measure) => measure.start.includes(hour) && measure.active_energy_imported !== null
      );

      const name = formatCSVHours(hour);
      row[name] = exists
        ? usages[key].find((item) => item.start.includes(hour))?.active_energy_imported
        : "NULL";
    });

    return row;
  });

  const headers = Object.keys(fileRows[0]);

  downloadFile(fileRows, `reporte_consumos_medidor_${meterSerial}`, headers);
};

const formatCSVHours = (hour: string) => {
  const number = parseInt(`H${hour.split(":")[0]}`.slice(1));
  const newNumber = number + 1;
  return "H" + newNumber.toString().padStart(2, "0");
};

export const createDownloadLink = ({ data, fileName, fileType }: DownloadFileModel) => {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export const downloadFile = (data: any[], fileName: string, headers: string[]) => {
  const csv = Papa.unparse({
    fields: headers,
    data: data,
  });

  createDownloadLink({
    data: "\uFEFF" + csv,
    fileName,
    fileType: "text/csv;charset=utf-8;",
  });
};

export const getDaysDiff = (since: string, until: string) => {
  const date1 = moment(until);
  const date2 = moment(since);

  const startOfDay1 = date1.startOf("day");
  const startOfDay2 = date2.startOf("day");

  const differenceInDays = startOfDay1.diff(startOfDay2, "days");

  return differenceInDays;
};

export const getLastReportAttemp = (attemps: ReportAttemps[]) => {
  if (attemps.length === 0) return null;

  let lastAttemp = attemps[0];
  let lastAttempDate = moment(lastAttemp.created_at);

  for (const attemp of attemps) {
    const currentDate = moment(attemp.created_at);
    if (currentDate.isAfter(lastAttempDate)) {
      lastAttempDate = currentDate;
      lastAttemp = attemp;
    }
  }

  return lastAttemp;
};

export const sortReportsByState = (a: XMReportsModel, b: XMReportsModel) => {
  const indexA = REPORTS_LIST_ORDER.indexOf(
    (a.last_attemp?.state as ReportAttempsStates) ?? ReportAttempsStates.NO_SENT
  );
  const indexB = REPORTS_LIST_ORDER.indexOf(
    (b.last_attemp?.state as ReportAttempsStates) ?? ReportAttempsStates.NO_SENT
  );

  return indexA - indexB;
};

export const sortReportResultsByState = (a: BorderResult, b: BorderResult) => {
  const indexA = REPORT_RESULT_LIST_ORDERS.indexOf(a.ResultFlag);
  const indexB = REPORT_RESULT_LIST_ORDERS.indexOf(b.ResultFlag);

  return indexA - indexB;
};
